:root {
  --sd-rating-bad-color: #{$red};
  --sd-rating-normal-color: #{$yellow};
  --sd-rating-good-color: #{$green};
  --sd-rating-bad-color-light: #{$red-light};
  --sd-rating-normal-color-light: #{$yellow-light};
  --sd-rating-good-color-light: #{$green-light};
}
.sd-rating {
  overflow-x: auto;
  min-height: calcSize(8);

  fieldset {
    display: flex;
    border: none;
    padding: 0 0 calcSize(2) 0;
    flex-wrap: nowrap;
    gap: calcSize(1);
    margin-inline-start: 0;
    align-items: center;
  }

  &.sd-rating--wrappable {
    fieldset {
      flex-wrap: wrap;
    }
  }
}

.sd-rating--small {
  min-height: calcSize(3);
  margin: auto;

  fieldset {
    padding: 0;
    gap: calcSize(1);
  }
}
.sd-rating__item {
  position: relative;
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(12.5);
  white-space: nowrap;
  padding: calcSize(0.5) calcSize(2.5);
  height: calcSize(6);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: calcSize(6);
  text-align: center;
  border: 2px solid $background;
  color: $foreground;
  fill: $foreground;
  font-size: calcFontSize(1);
}
.sd-rating__item--fixed-size {
  width: calcSize(6);
  padding: 0;
}

legend+.sd-rating__item,
legend+sv-ng-rating-item-smiley+.sd-rating__item-smiley,
legend+sv-ng-rating-item+.sd-rating__item {
  margin-inline-start: 2px;
}

.sd-rating__item--error {
  background-color: $red-light;
  box-shadow: 0px 1px 2px transparent;
  border-color: transparent;
}

.sd-rating__item.sd-rating__item--disabled {
  color: $foreground;
  fill: $foreground;
}

.sd-rating__item.sd-rating__item--selected.sd-rating__item--disabled {
  color: $primary-foreground;
  fill: $foreground;
}

.sd-question--disabled .sd-rating__item-text {
  opacity: 0.25;
}

.sd-rating__item--allowhover:hover {
  background-color: $background-dark;
  border-color: $background-dark;
}

.sd-rating__item:focus-within {
  border-color: $primary;
}

.sd-rating__item--selected {
  background-color: $primary;
  border-color: $primary;
  color: $primary-foreground;
  font-weight: 600;
  box-shadow: 0px 1px 2px transparent;
}

.sd-rating__item--selected:focus-within {
  box-shadow: inset 0 0 0 2px $background;
}

.sd-rating__item-smiley {
  position: relative;
  background: $background;
  border-radius: calcSize(12.5);
  white-space: nowrap;
  padding: calcSize(1.25);
  box-sizing: border-box;
  min-width: calcSize(6);
  text-align: center;
  border: 2px solid $border;
  color: $foreground;
  fill: $border;

  svg {
    display: block;
    width: calcSize(3);
    height: calcSize(3);
  }
}

.sd-rating__item-smiley--small {
  padding: calcSize(0.625);
  min-width: calcSize(3);
  border-width: 1px;

  svg {
    width: calcSize(1.5);
    height: calcSize(1.5);
  }
}

.sd-rating__item-smiley--small.sd-rating__item-smiley--selected:focus-within {
  box-shadow: inset 0 0 0 1px $background;
}
legend+.sd-rating__item-smiley,
legend+sv-ng-rating-item+.sd-rating__item-smiley {
  margin-inline-start: 2px;
}

.sd-rating__item-smiley--error {
  background-color: $red-light;
  border-color: transparent;
  fill: $foreground-light;
  
    &.sd-rating__item-smiley--scale-colored:not(.sd-rating__item-smiley--selected) {
      opacity: initial;
    }
}

.sd-rating__item-smiley.sd-rating__item-smiley--disabled {
  opacity: 0.5;
}

.sd-rating__item-smiley.sd-rating__item-smiley--selected.sd-rating__item-smiley--disabled {
  opacity: initial;
  fill: $primary-foreground;
}

.sd-rating__item-smiley--allowhover:hover {
  background-color: $background-dark;
  border-color: $border;
}

.sd-rating__item-smiley:focus-within {
  border-color: $primary;
}

.sd-rating__item-smiley--selected {
  background-color: $primary;
  border-color: $primary;
  fill: $primary-foreground;
  font-weight: 600;
}

.sd-rating__item-smiley--selected:focus-within {
  box-shadow: inset 0 0 0 2px $background;
}
.sd-rating__item-smiley--scale-colored:not(.sd-rating__item-smiley--selected) {
  opacity: 0.25;

  &.sd-rating__item-smiley--allowhover:hover {
    opacity: 0.5;
  }
}

.sd-rating__item-smiley--rate-colored:not(.sd-rating__item-smiley--selected) {
  &.sd-rating__item-smiley--allowhover:hover {
      opacity: 0.5;
  }
}

.sd-rating__item-smiley--scale-colored:not(.sd-rating__item-smiley--selected):focus-within {
  opacity: 1;
}
.sd-rating__item-star {
  position: relative;
  background: $background;
  width: calcSize(6);
  height: calcSize(6);
  &:not(:first-of-type) {
      padding-left: calcSize(0.5);
      margin-left: calcSize(-0.5);
    }
  
    &:not(:last-of-type) {
      padding-right: calcSize(0.5);
      margin-right: calcSize(-0.5);
    }

  svg {
    stroke: $border;
    fill: none;
    width: calcSize(6);
      height: calcSize(6);
      display: block;
  }

  .sv-star-2 {
    display: none;
  }
}

.sd-rating__item-star--small {
  width: calcSize(3);
  height: calcSize(3);

  svg {
    width: calcSize(3);
    height: calcSize(3);
  }
}
.sd-rating__item-star--selected {
  svg {
    stroke: none;
    fill: $primary;
  }
}

.sd-rating__item-star--error {
  svg {
    stroke: none;
    fill: $red-light;
  }
}

.sd-rating__item-star--disabled {
  opacity: 0.5;

  svg {
    stroke: $border;
    fill: none;
  }
}

.sd-rating__item-star--selected.sd-rating__item-star--disabled {
  svg {
    stroke: none;
    fill: $border;
  }
}

.sd-rating__item-star:focus-within {
  svg {
    stroke: $primary;
    fill: none;
  }
}

.sd-rating__item-star--unhighlighted {
  svg {
    stroke: none;
    fill: $border;
  }
}

.sd-rating__item-star--highlighted {
  svg {
    stroke: $border;
    fill: $background-dark;
  }
}

.sd-rating__item-star--selected.sd-rating__item-star--unhighlighted:focus-within {
  svg {
    stroke: $border;
    fill: $border;
  }
}
.sd-rating__item-star--selected:focus-within {
  svg {
    stroke: $primary;
    fill: $primary;
  }

  .sv-star {
    display: none;
  }

  .sv-star-2 {
    display: block;
  }
}
.sd-rating__item-text.sd-rating__item-text {
  font-family: $font-editorfont-family;
  font-weight: $font-editorfont-weight;
  color: $font-editorfont-color;
  font-size: $font-editorfont-size;
  line-height: calcSize(3);
  display: inline-block;

  &.sd-rating__min-text,
  &.sd-rating__max-text {
    margin-top: calcSize(1.25);
    margin-bottom: calcSize(1.25);
    border: 2px solid $background;
    color: $foreground-light;
  }

  &.sd-rating__min-text {
    margin-right: calcSize(1);
    // margin-left: calcSize(2);
    border-left: 0px;
  }

  &.sd-rating__max-text {
    margin-right: calcSize(2);
    margin-left: calcSize(1);
  }

  .sv-string-editor {
    white-space: nowrap;
  }
        &.sd-rating__item--fixed-size {
          min-width: calcSize(3);
        }
}