.card {
  border: 1px solid #d3d3d3;
  margin: 5%;
  padding: 2%;
  box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.innerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0%;
  padding-top: 0%;
}
.heading {
  text-align: center;
  font-family: "Avenir" sans-serif, monospace;
  font-size: 17px;
  font-weight: bold;
}

.typography {
  font-family: "Avenir" sans-serif, monospace;
  font-size: 17px;
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.link {
  font-family: "Avenir" sans-serif, monospace;
  font-size: 18px;
}
