
body #root {
  --primary: #284496;
  --primary-light: #7583cf;
  --secondary: #ffffff;
  --background: #f8f8f8;
  --foreground: black;
  --sjs-general-backcolor-dark: #284496;
}

.sd-btn--action:not(:disabled):hover {
  color: --primary-foreground;
  background-color: var(--primary);
}
