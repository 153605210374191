.card {
  border: 1px solid #d3d3d3;
  margin: 5%;
  padding: 2%;
  box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.2);
  overflow: auto;
}
.innerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0%;
  padding-top: 0%;
}
.typography {
  font-family: "Avenir" sans-serif, monospace;
  font-size: 18px;
  font-weight: 700;
}

.span {
  text-align: center;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}
