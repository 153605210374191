@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/Avenir35Light/font.woff2") format("woff2"),
    url("../assets/fonts/Avenir35Light/font.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Avenir" sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Avenir" sans-serif, monospace;
}
