.body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 200px);
}

.text {
  font-size: 1.25rem;
  font-weight: 400;
  color: #bbbbbb;
  font-family: "Avenir" sans-serif, monospace;
}
