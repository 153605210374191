.sd-body__navigation
  .sv-action:not(.sv-action--hidden)
  ~ .sv-action:not(.sv-action--hidden) {
  margin-left: 0;
}
.sd-root-modern--mobile .sd-body__navigation .sv-action:not(.sv-action--hidden),
.sd-root-modern--mobile .sd-body__navigation .sd-btn {
  flex-grow: 0;
}
.sd-body__navigation .sv-action--hidden {
  display: none;
}
.sd-body__navigation.sd-action-bar {
  flex-wrap: wrap;
  gap: 15px;
}
.sd-btn:disabled {
  background-color: #294199;
  color: #5a5a5a;
  opacity: 0.9;
  font-weight: bold;
}
.sd-btn {
  font-family: "Avenir" sans-serif, monospace;
  background-color: #294199;
  color: #ffffff;
}
.sd-navigation__next-btn {
  width: 150px;
  background-color: #294199;
  color: #ffffff;
}
.sd-body__navigation .sd-btn {
  width: 275px;
  margin-left: 30px;
}

.sv-string-viewer {
  font-family: "Avenir" sans-serif, monospace;
}
.sd-progress__text {
  font-family: "Avenir" sans-serif, monospace;
}

.sd-input {
  font-family: "Avenir" sans-serif, monospace;
}

.sd-element__num {
  font-family: "Avenir" sans-serif, monospace;
}
