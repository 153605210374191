
.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
 .dot1, .dot2, .dot3 {
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 7px;
}
.dot1 {
  animation: jump 1.6s -0.32s linear infinite;
  background: #62d7fa;
}
.dot2 {
    animation: jump 1.6s -0.16s linear infinite;
    background: #53a953;
}
.dot3 {
    animation: jump 1.6s linear infinite;
    background: #f34141;
  }
@keyframes jump {
  0%, 80%, 100% { 
    -webkit-transform: scale(.5);
    transform: scale(.5);
  } 40% { 
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}