

.modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.616);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      transition: all 0.3s ease-in-out;
    

     
}

 .open{
      opacity: 1;
      visibility: visible;
}

 .close{
      opacity: 0;
      visibility: hidden;
}


