.button {
  text-transform: none;
  letter-spacing: 1;
  font-size: 16px;
  width: 250px;
  font-family: "Avenir" sans-serif, monospace;
  background-color: #294199;
  padding: 16px 64px;
  margin: 10px 0 0 30px;
  color: #f8f8f8;
  font-weight: 700;
}
.rootDiv {
  display: flex;
  justify-content: center;
}
h2 {
  font-family: "Avenir" sans-serif, monospace;
}
.card {
  border: 0.5px solid #3f51b5;
  margin: 5%;
  margin-top: 1%;
  margin-bottom: 0;
  padding: 2%;
  box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.1);
  overflow: auto;
  border-radius: 10px;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  margin-top: 1rem;
}
.input {
  margin: 0 0.5rem;
}
.agreeTypography {
  margin-left: 2px;
  font-family: "Avenir", sans-serif;
}
.buttonParent {
  display: flex;
  margin: 2%;
  justify-content: flex-end;
  margin-right: 0px;
  margin-left: auto;
}
.error {
  color: red;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: -2.25rem;
}

.termsSpan {
  font-weight: bold;
}

.text {
  font-size: 1rem;
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
